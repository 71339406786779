<template lang="pug">
sidebar-tab-content#advanced.advanced-tab(ref="content")
  slot(name="before")
  slot
    spacing-accordion(v-bind="spacingProps")
    om-responsive-settings(v-if="!teaserSelected")
    custom-class-accordion(v-if="!pickAPresentSelected")
    set-element-defaults(:extras="elementDefaultsExtras")
    om-button.mt-5.w-100(
      v-if="canMoneyFormatSettingBeAdjusted"
      primary
      @click="$modal.show('money-format-modal')"
    ) Modify money format
    copy-element-id
  slot(name="after")
</template>
<script>
  import { ELEMENTS } from '@om/template-properties/src/propertyHelper';
  import { mapGetters, mapState } from 'vuex';

  export default {
    components: {
      SpacingAccordion: () => import('@/editor/components/sidebar/components/SpacingAccordion.vue'),
      CustomClassAccordion: () =>
        import('@/editor/components/sidebar/components/CustomClassAccordion.vue'),
    },
    props: {
      spacingProps: { type: Object, default: () => {} },
      elementDefaultsExtras: { type: Array, default: null },
    },
    computed: {
      ...mapState(['selectedElement', 'campaign']),
      ...mapGetters(['isSuperAdmin', 'domains']),
      contentRef() {
        return this.$refs.content;
      },
      teaserSelected() {
        return this.selectedElement.isTeaser;
      },
      pickAPresentSelected() {
        return this.selectedElement.type === 'OmPickAPresent';
      },
      isOMRecommender() {
        return (
          this.selectedElement.type === ELEMENTS.OmProduct &&
          this.selectedElement.data.mode === 'optimonk-recommender'
        );
      },
      canMoneyFormatSettingBeAdjusted() {
        if (!this.isSuperAdmin || !this.isOMRecommender) return false;

        const { platform = '' } =
          this.domains?.find?.(({ domain }) => domain === this.campaign.domain) ?? {};

        return platform !== 'shopify' && platform !== 'shoprenter';
      },
    },
    methods: {
      getId() {
        if (this.contentRef) {
          return this.contentRef.getId();
        }
      },
      show() {
        this.$refs.content.show();
      },
      hide() {
        this.$refs.content.hide();
      },
    },
  };
</script>
